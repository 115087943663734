/** @format */


.cancel-btn:active, .cancel-btn:focus,
.add-btn:active, .add-btn:hover {
  box-shadow: none;
  outline: none;
}
.add-btn {
  position:absolute;
  top:235px;
  right:32px;
  width:65px;
  height:65px;
  cursor:pointer;
}
.edit-btn {
  background: #F8F1EB;
  border-radius: 6px;
  width: 40px;
  padding: 0.375rem 0;
}
.edit-btn svg {
  font-size: 20px !important;
}
.add-btn img {
  width:55px;
  height:55px;
}

